<template>
  <Toast />
  <ConfirmDialog />
  <ul class="list-none p-0 m-0 flex align-items-center font-medium mb-3">
    <li>
      <a class="text-500 no-underline line-height-3 cursor-pointer"
        >Inventario</a
      >
    </li>
    <li class="px-2">
      <i class="pi pi-angle-right text-500 line-height-3"></i>
    </li>
    <li>
      <span class="text-900 line-height-3 active">Traspasos</span>
    </li>
  </ul>
  <div class="surface-section px-4 py-5 md:px-12 lg:px-12">
    <div
      class="flex align-items-center flex-column lg:justify-content-center lg:flex-row"
    >
      <h4>
        <strong>REGISTRAR TRASPASO DE PRODUCTOS</strong>
      </h4>
    </div>
    <div class="col-12">
      <div class="p-fluid formgrid grid p-fondo">
        <div class="field col-12 md:col-3">
          <label for="sucursal"><strong>SUCURSAL ORIGEN:</strong></label>
          <Dropdown
            v-model="sucursalSelected"
            :options="sucursales"
            optionLabel="nombre"
            optionValue="id"
          >
          </Dropdown>
        </div>
        <div class="field col-12 md:col-3">
          <label for="sucursal"><strong>SUCURSAL DESTINO:</strong></label>
          <Dropdown
            v-model="sucursalDestinoSelected"
            :options="sucursales"
            optionLabel="nombre"
            optionValue="id"
          >
          </Dropdown>
        </div>
        <div class="field col-12 md:col-4">
          <label for=""><strong>BUSCAR PRODUCTO:</strong></label>
          <AutoComplete
            :dropdown="true"
            field="descripcion"
            v-model="productoSelected"
            :suggestions="productosListadoFiltrado"
            @complete="buscarProducto($event)"
            placeholder="Buscar Producto"
            @item-select="agregarProductoDetalle"
          >
            <template #item="slotProps">
              <div>
                {{ slotProps.item.descripcion }} <strong>**</strong>
                {{ slotProps.item.fabrica_nombre }} <strong>**</strong>
                <span
                  :class="
                    stockClass(
                      slotProps.item.stock.length > 0
                        ? slotProps.item.stock[0].cantidad
                        : 0
                    )
                  "
                  ><strong>{{
                    slotProps.item.stock.length > 0
                      ? slotProps.item.stock[0].cantidad
                      : 0
                  }}</strong></span
                >
              </div>
            </template>
          </AutoComplete>
          <small class="p-invalid" v-if="errors.productoSelected">{{
            errors.productoSelected[0]
          }}</small>
        </div>
        <div class="field col-12 md:col-2">
          <label for="acciones"><strong>ACCIONES:</strong></label>
          <Button
            label="AGREGAR"
            icon="pi pi-plus"
            class="p-button-primary"
            v-tooltip.top="'Agregar el Producto al detalle'"
            @click="agregarProductoDetalle"
          />
        </div>
      </div>
      <div class="mt-4 col-12">
        <DataTable
          ref="traspasos"
          key="id"
          :value="traspasos"
          responsiveLayout="scroll"
          :loading="enviando"
          class="p-datatable-sm"
          responsive="true"
          editMode="cell"
          stripedRows
          showGridlines
          selectionMode="single click"
          @cell-edit-complete="guardandoCambiosCelda"
        >
          <template #loading
            ><div class="flex align-items-center justify-content-center">
              <ProgressSpinner /></div
          ></template>
          <template #empty>
            <div class="flex align-items-center justify-content-center">
              <div class="p-invalid">No existen Productos en el detalle!</div>
            </div>
          </template>
          <Column field="id" header="CÓDIGO" class="text-right"></Column>
          <Column
            field="producto"
            header="DETALLE/PRODUCTO"
            style="font-weight: bold"
          ></Column>
          <Column
            field="stock_actual"
            header="STOCK ACTUAL"
            class="text-right"
          ></Column>
          <Column
            class="text-center bold bg-green-200"
            field="cantidad"
            header="CANTIDAD"
          >
            <template #body="slotProps">
              {{ slotProps.data.cantidad }}
            </template>
            <template #editor="slotProps">
              <InputNumber
                :inputClass="'text-right'"
                :min="0"
                :minFractionDigits="0"
                locale="de-DE"
                v-model="slotProps.data.cantidad"
              />
            </template>
          </Column>
          <Column field="precio_compra" header="PUCompra" class="text-right">
            {{ data.precio_compra }}
          </Column>
          <Column field="precio_venta" header="PUVenta" class="text-right">
            {{ data.precio_venta }}
          </Column>
          <Column header="ACCIONES">
            <template #body="slotProps">
              <Button
                @click="quitarProductoDetalle(slotProps)"
                v-tooltip.top="'Quitar Producto'"
                icon="pi pi-times"
                class="p-button-rounded p-button-danger p-button-text"
              />
            </template>
          </Column>
        </DataTable>
      </div>
      <div class="flex align-items-center justify-content-end">
        <Button
          label="REALIZAR TRASPASO"
          icon="pi pi-save"
          class="p-button-primary p-button-lg"
          v-tooltip.top="'Registrar Movimiento'"
          @click="guardarMovimiento"
          :disabled="sucursales.length <= 1"
        />
      </div>
    </div>
  </div>
</template>
<script>
import SucursalService from "@/service/SucursalService";
import ProductService from "@/service/ProductService";
import MovimientoInventarioService from "@/service/MovimientoInventarioService";
export default {
  data() {
    return {
      traspasos: [],
      sucursalSelected: 1,
      sucursalDestinoSelected: 2,
      sucursales: [],
      tipoMovimientos: [],
      tipoMovimientoSelected: 1,
      productoSelected: null,
      productosListadoFiltrado: [],
      fecha_movimiento: new Date(),
      enviando: false,
      errors: {},
      stockClass: (data) => {
        if (data <= 0) {
          return "outofstock";
        } else if (data <= 10) {
          return "lowstock";
        } else {
          return "instock";
        }
      },
    };
  },
  sucursalService: null,
  productService: null,
  movimientoInventarioService: null,
  created() {
    this.sucursalService = new SucursalService();
    this.productService = new ProductService();
    this.movimientoInventarioService = new MovimientoInventarioService();
  },
  mounted() {
    this.cargarSucursales();
  },
  methods: {
    validarFormulario() {
      if (
        this.sucursalSelected == null ||
        this.sucursalDestinoSelected == null
      ) {
        this.$toast.add({
          severity: "error",
          summary: "Error",
          detail: "Debe seleccionar una sucursal",
          life: 3000,
        });
        return false;
      }
      if (this.traspasos.length == 0) {
        this.$toast.add({
          severity: "error",
          summary: "Error",
          detail: "Debe agregar al menos un producto al detalle",
          life: 3000,
        });
        return false;
      }
      return true;
    },
    guardarMovimiento() {
      if (this.validarFormulario()) {
        this.enviando = true;
        let datos = {
          sucursal_id: this.sucursalSelected,
          sucursal_destino_id: this.sucursalDestinoSelected,
          productos: this.traspasos,
        };
        this.$confirm.require({
          header: "Confirmación",
          message: "¿ESTÁS SEGURO DE GUARDAR EL MOVIMIENTO?",
          icon: "pi pi-exclamation-triangle",
          acceptLabel: "Si, Guardar",
          rejectLabel: "No, Cancelar",
          acceptClass: "p-button-primary p-button-lg",
          rejectClass: "p-button-danger p-button-lg",
          acceptIcon: "pi pi-save",
          rejectIcon: "pi pi-times",
          discard: () => {
            this.guardando = false;
          },
          accept: () => {
            this.movimientoInventarioService
              .nuevoMovimiento(datos)
              .then((data) => {
                if (data.status == 400) {
                  this.$toast.add({
                    severity: "error",
                    summary: "Error",
                    detail: data.message,
                    life: 3000,
                  });
                  this.enviando = false;
                  return;
                } else {
                  this.$toast.add({
                    severity: "success",
                    summary: "Éxito",
                    detail: data.message,
                    life: 3000,
                  });
                  this.enviando = false;
                  this.traspasos = [];
                  /* this.imprimirMovimiento(mov_almacen.id); */
                }
              });
          },
          reject: () => {
            this.$toast.add({
              severity: "error",
              summary: "Error",
              detail: "No se guardo el movimiento",
              life: 3000,
            });
            this.enviando = false;
          },
        });
      }
    },
    /*  imprimirMovimiento(id) {
      this.nuevoMovimiento
        .imprimirMov(id)
        .then(() => {})
        .catch((error) => console.log(error));
    }, */
    guardandoCambiosCelda(e) {
      let { data, newValue, field } = e;
      data[field] = newValue;
      //valido de la cantidad no sea mayor a la existencia
      if (field == "cantidad") {
        if (newValue > data.stock_actual) {
          this.$toast.add({
            severity: "error",
            summary: "Error",
            detail: "La cantidad no puede ser mayor al stock actual",
            life: 3000,
          });
          data.cantidad = parseFloat(data.stock_actual);
        }
      }
    },
    agregarProductoDetalle() {
      if (this.productoSelected == null) {
        this.$toast.add({
          severity: "error",
          summary: "Error",
          detail: "Debe seleccionar un producto",
          life: 3000,
        });
        return;
      }
      //Verificar si el producto ya esta en el detalle
      let existe = this.traspasos.find((item) => {
        return item.id == this.productoSelected.id;
      });

      if (existe) {
        this.errors.productoSelect = ["El producto ya esta en el detalle"];
        this.$toast.add({
          severity: "error",
          summary: "Error",
          detail: "El producto ya esta en el detalle",
          life: 3000,
        });
        this.enviando = false;
        return false;
      }

      if (this.cantidad <= 0 || this.cantidad === null) {
        this.errors.cantidad = ["La cantidad debe ser mayor a 0"];
        return false;
      }

      //verifico el stock_actual del producto seleccionado y si no tiene stock no lo agrego al detalle
      if (this.productoSelected.stock_actual[0] <= 0) {
        this.$toast.add({
          severity: "error",
          summary: "Error",
          detail: "El producto no tiene stock",
          life: 3000,
        });
        return false;
      }

      let producto = this.productoSelected;
      let cantidad = 1;
      let detalle = {
        id: producto.id,
        producto: producto.descripcion,
        stock_actual: parseFloat(producto.stock[0].cantidad),
        cantidad: parseFloat(cantidad),
        precio_compra: parseFloat(producto.stock[0].cpp) || 0,
        precio_venta: parseFloat(producto.stock[0].ultimo_precio) || 0,
        margen:
          parseFloat(producto.stock[0].ultimo_precio) -
          parseFloat(producto.precio_compra),
        margen_porcentaje:
          (parseFloat(producto.stock[0].ultimo_precio) /
            parseFloat(producto.precio_compra)) *
          100,
        redondeo:
          parseFloat(producto.precio_compra) +
          parseFloat(producto.stock[0].ultimo_precio) -
          parseFloat(producto.precio_compra),
      };

      this.traspasos.push(detalle);
      this.productoSelected = null;
    },
    buscarProducto(event) {
      setTimeout(() => {
        this.productService
          .buscarXNombre({
            texto: event.query,
            sucursal_id: this.sucursalSelected,
          })
          .then((response) => {
            this.productosListadoFiltrado = [...response.productos];
          })
          .catch((error) => console.log(error));
      }, 250);
    },
    cargarSucursales() {
      this.sucursalService
        .getSucursalesAll()
        .then((response) => {
          response.forEach((sucursal) => {
            this.sucursales.push(sucursal);
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },
    quitarProductoDetalle(slotProps) {
      this.traspasos.splice(slotProps.rowIndex, 1);
    },
  },
  watch: {
    sucursalSelected() {
      //recorro las sucursales para obtener el id de la sucursal destino
      this.sucursales.forEach((sucursal) => {
        if (sucursal.id != this.sucursalSelected) {
          this.sucursalDestinoSelected = sucursal.id;
        }
      });
    },

    sucursalDestinoSelected() {
      this.sucursales.forEach((sucursal) => {
        if (sucursal.id != this.sucursalDestinoSelected) {
          this.sucursalSelected = sucursal.id;
        }
      });
    },
  },
};
</script>
<style scoped lang="scss">
@import "@/assets/demo/badges.scss";
.p-invalid {
  color: red;
}
.p-fondo {
  background-color: #7bffae;
  border-radius: 5px;
}
.outofstock {
  font-weight: 700;
  color: #000000;
  background-color: #ff5252;
  padding: 0 0.5em;
  border-radius: 0.5em;
}

.lowstock {
  font-weight: 700;
  color: #000000;
  background-color: #fbc02d;
  padding: 0 0.5em;
  border-radius: 0.5em;
}

.instock {
  font-weight: 700;
  color: #000000;
  background-color: #7bffae;
  padding: 0 0.5em;
  border-radius: 0.5em;
}
</style>
